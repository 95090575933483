const talentData = [
    {
        postName: "移动端开发工程师", //岗位名称
        jobDescription:
            "工作职责: 1. 基于Flutter技术栈，负责公司移动端APP前端产品研发工作；2. 针对业务需求进行基础库、组件库、二方库、研发工具链的开发工作；3. 对现有业务代码进行性能调优。任职要求：1. 专科及以上学历，计算机专业优先；2. 熟练的编程功底，良好的数据结构和算法基础；3. 有OC/Java移动端开发经验者优先；3. 对新技术保持热情，具备良好的分析、解决问题的能力；", //岗位描述
        postSalary: "15-21K", //岗位薪资
        educationalRequirements: "本科", //学历要求
        workingYears: "3-5年", //工作年限
        corporateName: "锦奇（上海）医疗投资有限公司", //公司名称
        companyAddress: "上海长宁区中关村·虹桥创新中心南7座7座10楼", //公司地址
        companyResume:
            "锦奇医疗创立于2007年，致力于生殖健康服务，是集实体医疗机构、互联网医院、海外生殖医疗服务、医药流通、医药研发、器械、检验及进口保健品生产与销售一体的国际化生殖医疗集团。基于对生殖健康领域的深度理解与精耕细作，依托“生殖健康供应链综合服务平台”的渠道优势，以医疗服务为中心节点，以专家智慧和人工智能为价值枢纽，链接先进生命医学技术，打造生殖健康功能网，协同科技与人性，赋能生命。专业护航，让生命传承。锦奇医疗秉持“回归医疗本质，以用户为中心”的服务理念,以“专业、专注、守正、利他”的核心价值理念，链接信息、整合国内外先进的生殖医疗科技，与国内外各大医疗机构及产业链中的各个供应商合作，构建生殖医疗完整的生态系统。同时联动国内外生殖专家，打破时间、空间限制，将用户、医院、机构链接起来，形成“诊前诊中诊后一体、线上线下互动、院内院外互通”的新型智慧医疗服务模式，为备孕、优孕、不孕用户提供整体生育解决方案和全生命周期管理。从备孕到优孕、从生殖到生命好孕未来，绽放“生”之美好", //公司简介
        contacts: "朱攀", //联系人
        telephone: "13007142502", //联系电话
        id: 1,
    },
    {
        postName: "移动端开发工程师（跨平台）", //岗位名称
        jobDescription:
            "工作职责: 1. 基于Flutter技术栈，负责公司移动端APP前端产品研发工作；2. 针对业务需求进行基础库、组件库、二方库、研发工具链的开发工作；3. 对现有业务代码进行性能调优。职位要求：1. 专科及以上学历，计算机专业优先；2. 熟练的编程功底，良好的数据结构和算法基础；3. 有OC/Java移动端开发经验者优先；3. 对新技术保持热情，具备良好的分析、解决问题的能力；4. 熟悉Dart语言编程，且有成熟项目开发经验；5. 对产品有好奇心，关注业内产品，喜欢使用产品，关注产品体验；6. 热爱移动产品研发，有较强的学习能力，有强烈的求知欲、好奇心和进取心 ，能及时关注和学习业界最新的移动开发技术。", //岗位描述
        postSalary: "13-20K", //岗位薪资
        educationalRequirements: "大专", //学历要求
        workingYears: "1-3年", //工作年限
        corporateName: "锦奇（上海）医疗投资有限公司", //公司名称
        companyAddress: "上海长宁区中关村·虹桥创新中心南7座7座10楼", //公司地址
        companyResume:
            "锦奇医疗创立于2007年，致力于生殖健康服务，是集实体医疗机构、互联网医院、海外生殖医疗服务、医药流通、医药研发、器械、检验及进口保健品生产与销售一体的国际化生殖医疗集团。基于对生殖健康领域的深度理解与精耕细作，依托“生殖健康供应链综合服务平台”的渠道优势，以医疗服务为中心节点，以专家智慧和人工智能为价值枢纽，链接先进生命医学技术，打造生殖健康功能网，协同科技与人性，赋能生命。专业护航，让生命传承。锦奇医疗秉持“回归医疗本质，以用户为中心”的服务理念,以“专业、专注、守正、利他”的核心价值理念，链接信息、整合国内外先进的生殖医疗科技，与国内外各大医疗机构及产业链中的各个供应商合作，构建生殖医疗完整的生态系统。同时联动国内外生殖专家，打破时间、空间限制，将用户、医院、机构链接起来，形成“诊前诊中诊后一体、线上线下互动、院内院外互通”的新型智慧医疗服务模式，为备孕、优孕、不孕用户提供整体生育解决方案和全生命周期管理。从备孕到优孕、从生殖到生命好孕未来，绽放“生”之美好", //公司简介
        contacts: "朱攀", //联系人
        telephone: "13007142502", //联系电话
        id: 2,
    },
    {
        postName: "互联网医助", //岗位名称
        jobDescription:
            "工作职责: 1.主要负责医生资料审核、线上用户医学问题的解答等；2.帮助用户发现问题，解决问题；3.用户端详细问题的解答，整理用户资料，疾病档案的归类。任职要求：1、大专以上学历，2年左右服务行业工作优先；2、学习能力强，有耐心有服务意识，擅长沟通协调；3、医学，护理学专业或医助优先。", //岗位描述
        postSalary: "7-10K", //岗位薪资
        educationalRequirements: "大专", //学历要求
        workingYears: "1-3年", //工作年限
        corporateName: "锦奇（上海）医疗投资有限公司", //公司名称
        companyAddress: "上海长宁区中关村·虹桥创新中心南7座7座10楼", //公司地址
        companyResume:
            "锦奇医疗创立于2007年，致力于生殖健康服务，是集实体医疗机构、互联网医院、海外生殖医疗服务、医药流通、医药研发、器械、检验及进口保健品生产与销售一体的国际化生殖医疗集团。基于对生殖健康领域的深度理解与精耕细作，依托“生殖健康供应链综合服务平台”的渠道优势，以医疗服务为中心节点，以专家智慧和人工智能为价值枢纽，链接先进生命医学技术，打造生殖健康功能网，协同科技与人性，赋能生命。专业护航，让生命传承。锦奇医疗秉持“回归医疗本质，以用户为中心”的服务理念,以“专业、专注、守正、利他”的核心价值理念，链接信息、整合国内外先进的生殖医疗科技，与国内外各大医疗机构及产业链中的各个供应商合作，构建生殖医疗完整的生态系统。同时联动国内外生殖专家，打破时间、空间限制，将用户、医院、机构链接起来，形成“诊前诊中诊后一体、线上线下互动、院内院外互通”的新型智慧医疗服务模式，为备孕、优孕、不孕用户提供整体生育解决方案和全生命周期管理。从备孕到优孕、从生殖到生命好孕未来，绽放“生”之美好", //公司简介
        contacts: "朱攀", //联系人
        telephone: "13007142502", //联系电话
        id: 3,
    },
    {
        postName: "陪诊专员", //岗位名称
        jobDescription:
            "工作职责: 1、为客户在医院就诊提供现场全程跟踪指导服务；2、就诊前，与客户家属、专家详细沟通客户病情；3、就诊后，及时和医疗顾问沟通客户情况；4、协和客户完成事务性工作（例如代开药、取药等）；5、完善客户档案，表达主观及客观情况，提高客户满意度。岗位要求：1、中专以上学历，医、药相关专业及经历，有临床、护理经验优先；2、具有良好的沟通能力与客户服务意识，有良好的书面文字表达能力；3、身体健康，有责任心，有服务意识。", //岗位描述
        postSalary: "6-10K", //岗位薪资
        educationalRequirements: "中专/中技", //学历要求
        workingYears: "1-3年", //工作年限
        corporateName: "锦奇（上海）医疗投资有限公司", //公司名称
        companyAddress: "上海长宁区中关村·虹桥创新中心南7座7座10楼", //公司地址
        companyResume:
            "锦奇医疗创立于2007年，致力于生殖健康服务，是集实体医疗机构、互联网医院、海外生殖医疗服务、医药流通、医药研发、器械、检验及进口保健品生产与销售一体的国际化生殖医疗集团。基于对生殖健康领域的深度理解与精耕细作，依托“生殖健康供应链综合服务平台”的渠道优势，以医疗服务为中心节点，以专家智慧和人工智能为价值枢纽，链接先进生命医学技术，打造生殖健康功能网，协同科技与人性，赋能生命。专业护航，让生命传承。锦奇医疗秉持“回归医疗本质，以用户为中心”的服务理念,以“专业、专注、守正、利他”的核心价值理念，链接信息、整合国内外先进的生殖医疗科技，与国内外各大医疗机构及产业链中的各个供应商合作，构建生殖医疗完整的生态系统。同时联动国内外生殖专家，打破时间、空间限制，将用户、医院、机构链接起来，形成“诊前诊中诊后一体、线上线下互动、院内院外互通”的新型智慧医疗服务模式，为备孕、优孕、不孕用户提供整体生育解决方案和全生命周期管理。从备孕到优孕、从生殖到生命好孕未来，绽放“生”之美好", //公司简介
        contacts: "朱攀", //联系人
        telephone: "13007142502", //联系电话
        id: 4,
    },
    {
        postName: "妇产科医药代表（衢州市）", //岗位名称
        jobDescription:
            "工作职责: 1、负责医院医生的洽谈与APP推广工作2、负责药品在线上销售3、公司提供强大支持，产品已临床14年之久，背书强大，学术支持，科会支持，资金支持！4、科室针对性强，产品多样化，不单一，厂家直销，收益长期保障！职位要求：1、有生殖领域、妇产科领域医生资源。与以上领域医生有良好的合作关系。2、兼职工作！工作时间极短，收益大！", //岗位描述
        postSalary: "15-30K", //岗位薪资
        educationalRequirements: "高中", //学历要求
        workingYears: "1-3年", //工作年限
        corporateName: "锦奇（上海）医疗投资有限公司", //公司名称
        companyAddress: "上海长宁区中关村·虹桥创新中心南7座7座10楼", //公司地址
        companyResume:
            "锦奇医疗创立于2007年，致力于生殖健康服务，是集实体医疗机构、互联网医院、海外生殖医疗服务、医药流通、医药研发、器械、检验及进口保健品生产与销售一体的国际化生殖医疗集团。基于对生殖健康领域的深度理解与精耕细作，依托“生殖健康供应链综合服务平台”的渠道优势，以医疗服务为中心节点，以专家智慧和人工智能为价值枢纽，链接先进生命医学技术，打造生殖健康功能网，协同科技与人性，赋能生命。专业护航，让生命传承。锦奇医疗秉持“回归医疗本质，以用户为中心”的服务理念,以“专业、专注、守正、利他”的核心价值理念，链接信息、整合国内外先进的生殖医疗科技，与国内外各大医疗机构及产业链中的各个供应商合作，构建生殖医疗完整的生态系统。同时联动国内外生殖专家，打破时间、空间限制，将用户、医院、机构链接起来，形成“诊前诊中诊后一体、线上线下互动、院内院外互通”的新型智慧医疗服务模式，为备孕、优孕、不孕用户提供整体生育解决方案和全生命周期管理。从备孕到优孕、从生殖到生命好孕未来，绽放“生”之美好", //公司简介
        contacts: "朱攀", //联系人
        telephone: "13007142502", //联系电话
        id: 5,
    },
    {
        postName: "医药代表", //岗位名称
        jobDescription:
            "工作职责: 1、市场开发与维护：负责所辖区域目标医院开发工作与维护，以客户为中心做好相关医学服务；2、学术推广：根据客户需求有针对性的选择合适的学术推广方式，确保产品信息的准确传递；3、客户管理：深入了解客户需求，讲目标客户进行分级管理，合理安排拜访频率，与目标医院或医生建立并保持长期的合作关系；无需出差。4、完成公司及上级安排的其它工作。职位要求：1、大专以上学历，医学、药品等相关专业优先；2、成长型心态，积极主动，具有强烈的学习与成长意愿；3、良好的个人展示能力，沟通说服能力、抗压能力及团队合作能力；4、注重个人仪表，做事注重细节并且持之以恒。", //岗位描述
        postSalary: "13-25K", //岗位薪资
        educationalRequirements: "大专", //学历要求
        workingYears: "1-3年", //工作年限
        corporateName: "锦奇（上海）医疗投资有限公司", //公司名称
        companyAddress: "上海长宁区中关村·虹桥创新中心南7座7座10楼", //公司地址
        companyResume:
            "锦奇医疗创立于2007年，致力于生殖健康服务，是集实体医疗机构、互联网医院、海外生殖医疗服务、医药流通、医药研发、器械、检验及进口保健品生产与销售一体的国际化生殖医疗集团。基于对生殖健康领域的深度理解与精耕细作，依托“生殖健康供应链综合服务平台”的渠道优势，以医疗服务为中心节点，以专家智慧和人工智能为价值枢纽，链接先进生命医学技术，打造生殖健康功能网，协同科技与人性，赋能生命。专业护航，让生命传承。锦奇医疗秉持“回归医疗本质，以用户为中心”的服务理念,以“专业、专注、守正、利他”的核心价值理念，链接信息、整合国内外先进的生殖医疗科技，与国内外各大医疗机构及产业链中的各个供应商合作，构建生殖医疗完整的生态系统。同时联动国内外生殖专家，打破时间、空间限制，将用户、医院、机构链接起来，形成“诊前诊中诊后一体、线上线下互动、院内院外互通”的新型智慧医疗服务模式，为备孕、优孕、不孕用户提供整体生育解决方案和全生命周期管理。从备孕到优孕、从生殖到生命好孕未来，绽放“生”之美好", //公司简介
        contacts: "朱攀", //联系人
        telephone: "13007142502", //联系电话
        id: 6,
    },
    {
        postName: "妇产科医药代表（温州市）", //岗位名称
        jobDescription:
            "工作职责: 1、负责医院医生的洽谈与APP推广工作2、负责药品在线上销售3、公司提供强大支持，产品已临床14年之久，背书强大，学术支持，科会支持，资金支持！4、科室针对性强，产品多样化，不单一，厂家直销，收益长期保障！职位要求：1、有生殖领域、妇产科领域医生资源。与以上领域医生有良好的合作关系。2、兼职工作！工作时间极短，收益大！", //岗位描述
        postSalary: "15-30K", //岗位薪资
        educationalRequirements: "高中", //学历要求
        workingYears: "1-3年", //工作年限
        corporateName: "锦奇（上海）医疗投资有限公司", //公司名称
        companyAddress: "上海长宁区中关村·虹桥创新中心南7座7座10楼", //公司地址
        companyResume:
            "锦奇医疗创立于2007年，致力于生殖健康服务，是集实体医疗机构、互联网医院、海外生殖医疗服务、医药流通、医药研发、器械、检验及进口保健品生产与销售一体的国际化生殖医疗集团。基于对生殖健康领域的深度理解与精耕细作，依托“生殖健康供应链综合服务平台”的渠道优势，以医疗服务为中心节点，以专家智慧和人工智能为价值枢纽，链接先进生命医学技术，打造生殖健康功能网，协同科技与人性，赋能生命。专业护航，让生命传承。锦奇医疗秉持“回归医疗本质，以用户为中心”的服务理念,以“专业、专注、守正、利他”的核心价值理念，链接信息、整合国内外先进的生殖医疗科技，与国内外各大医疗机构及产业链中的各个供应商合作，构建生殖医疗完整的生态系统。同时联动国内外生殖专家，打破时间、空间限制，将用户、医院、机构链接起来，形成“诊前诊中诊后一体、线上线下互动、院内院外互通”的新型智慧医疗服务模式，为备孕、优孕、不孕用户提供整体生育解决方案和全生命周期管理。从备孕到优孕、从生殖到生命好孕未来，绽放“生”之美好", //公司简介
        contacts: "朱攀", //联系人
        telephone: "13007142502", //联系电话
        id: 7,
    },
    {
        postName: "苏州妇产科医药代表（全江苏招聘）", //岗位名称
        jobDescription:
            "工作职责: 1、负责医院医生的洽谈与APP推广工作2、负责药品在线上销售职位要求：1、有生殖领域、妇产科领域医生资源。与以上领域医生有良好的合作关系。2、兼职工作", //岗位描述
        postSalary: "10-15K", //岗位薪资
        educationalRequirements: "学历不限", //学历要求
        workingYears: "经验不限", //工作年限
        corporateName: "锦奇（上海）医疗投资有限公司", //公司名称
        companyAddress: "上海长宁区中关村·虹桥创新中心南7座7座10楼", //公司地址
        companyResume:
            "锦奇医疗创立于2007年，致力于生殖健康服务，是集实体医疗机构、互联网医院、海外生殖医疗服务、医药流通、医药研发、器械、检验及进口保健品生产与销售一体的国际化生殖医疗集团。基于对生殖健康领域的深度理解与精耕细作，依托“生殖健康供应链综合服务平台”的渠道优势，以医疗服务为中心节点，以专家智慧和人工智能为价值枢纽，链接先进生命医学技术，打造生殖健康功能网，协同科技与人性，赋能生命。专业护航，让生命传承。锦奇医疗秉持“回归医疗本质，以用户为中心”的服务理念,以“专业、专注、守正、利他”的核心价值理念，链接信息、整合国内外先进的生殖医疗科技，与国内外各大医疗机构及产业链中的各个供应商合作，构建生殖医疗完整的生态系统。同时联动国内外生殖专家，打破时间、空间限制，将用户、医院、机构链接起来，形成“诊前诊中诊后一体、线上线下互动、院内院外互通”的新型智慧医疗服务模式，为备孕、优孕、不孕用户提供整体生育解决方案和全生命周期管理。从备孕到优孕、从生殖到生命好孕未来，绽放“生”之美好", //公司简介
        contacts: "朱攀", //联系人
        telephone: "13007142502", //联系电话
        id: 8,
    },
    {
        postName: "服务员(兼职）", //岗位名称
        jobDescription:
            "工作职责: 1、熟悉公司小餐厅的工作情况，检查备用餐具是否齐全，餐台上永平是否整洁、齐备；2、工作时做到口勤、眼勤、手勤、脚勤，及时了解客人心态、需求；3、有牢固的业务操作知识，掌握客人的用餐规律；4、接到客人礼貌、周到、耐心，是可忍宾至如归。岗位要求：1、形象气质佳，有服务行业相关经验；2、有工作责任心，有独立处理用餐事务的能力；3、加强业务知识学习，不断掌握服务技能，提高服务质量。", //岗位描述
        postSalary: "4-5K", //岗位薪资
        educationalRequirements: "学历不限", //学历要求
        workingYears: "经验不限", //工作年限
        corporateName: "锦奇（上海）医疗投资有限公司", //公司名称
        companyAddress: "上海长宁区中关村·虹桥创新中心南7座7座10楼", //公司地址
        companyResume:
            "锦奇医疗创立于2007年，致力于生殖健康服务，是集实体医疗机构、互联网医院、海外生殖医疗服务、医药流通、医药研发、器械、检验及进口保健品生产与销售一体的国际化生殖医疗集团。基于对生殖健康领域的深度理解与精耕细作，依托“生殖健康供应链综合服务平台”的渠道优势，以医疗服务为中心节点，以专家智慧和人工智能为价值枢纽，链接先进生命医学技术，打造生殖健康功能网，协同科技与人性，赋能生命。专业护航，让生命传承。锦奇医疗秉持“回归医疗本质，以用户为中心”的服务理念,以“专业、专注、守正、利他”的核心价值理念，链接信息、整合国内外先进的生殖医疗科技，与国内外各大医疗机构及产业链中的各个供应商合作，构建生殖医疗完整的生态系统。同时联动国内外生殖专家，打破时间、空间限制，将用户、医院、机构链接起来，形成“诊前诊中诊后一体、线上线下互动、院内院外互通”的新型智慧医疗服务模式，为备孕、优孕、不孕用户提供整体生育解决方案和全生命周期管理。从备孕到优孕、从生殖到生命好孕未来，绽放“生”之美好", //公司简介
        contacts: "朱攀", //联系人
        telephone: "13007142502", //联系电话
        id: 9,
    },
    {
        postName: "妇产科医药代表（丽水市）", //岗位名称
        jobDescription:
            "工作职责: 1、负责医院医生的洽谈与APP推广工作；2、负责药品在线上销售；3、公司提供强大支持，产品已临床14年之久，背书强大，学术支持，科会支持，资金支持！4、科室针对性强，产品多样化，不单一，厂家直销，收益长期保障！职位要求：1、有生殖领域、妇产科领域医生资源。与以上领域医生有良好的合作关系。2、兼职工作！工作时间极短，收益大！", //岗位描述
        postSalary: "15-30K", //岗位薪资
        educationalRequirements: "高中", //学历要求
        workingYears: "1-3年", //工作年限
        corporateName: "锦奇（上海）医疗投资有限公司", //公司名称
        companyAddress: "上海长宁区中关村·虹桥创新中心南7座7座10楼", //公司地址
        companyResume:
            "锦奇医疗创立于2007年，致力于生殖健康服务，是集实体医疗机构、互联网医院、海外生殖医疗服务、医药流通、医药研发、器械、检验及进口保健品生产与销售一体的国际化生殖医疗集团。基于对生殖健康领域的深度理解与精耕细作，依托“生殖健康供应链综合服务平台”的渠道优势，以医疗服务为中心节点，以专家智慧和人工智能为价值枢纽，链接先进生命医学技术，打造生殖健康功能网，协同科技与人性，赋能生命。专业护航，让生命传承。锦奇医疗秉持“回归医疗本质，以用户为中心”的服务理念,以“专业、专注、守正、利他”的核心价值理念，链接信息、整合国内外先进的生殖医疗科技，与国内外各大医疗机构及产业链中的各个供应商合作，构建生殖医疗完整的生态系统。同时联动国内外生殖专家，打破时间、空间限制，将用户、医院、机构链接起来，形成“诊前诊中诊后一体、线上线下互动、院内院外互通”的新型智慧医疗服务模式，为备孕、优孕、不孕用户提供整体生育解决方案和全生命周期管理。从备孕到优孕、从生殖到生命好孕未来，绽放“生”之美好", //公司简介
        contacts: "朱攀", //联系人
        telephone: "13007142502", //联系电话
        id: 10,
    },
    {
        postName: "服务员(妇产科医药销售（全江苏）", //岗位名称
        jobDescription:
            "工作职责: 1、负责医院医生的洽谈与APP推广工作2、负责药品在线上销售职位要求：1、有生殖领域、妇产科领域医生资源。与以上领域医生有良好的合作关系。2、兼职工作", //岗位描述
        postSalary: "10-15K", //岗位薪资
        educationalRequirements: "学历不限", //学历要求
        workingYears: "经验不限", //工作年限
        corporateName: "锦奇（上海）医疗投资有限公司", //公司名称
        companyAddress: "上海长宁区中关村·虹桥创新中心南7座7座10楼", //公司地址
        companyResume:
            "锦奇医疗创立于2007年，致力于生殖健康服务，是集实体医疗机构、互联网医院、海外生殖医疗服务、医药流通、医药研发、器械、检验及进口保健品生产与销售一体的国际化生殖医疗集团。基于对生殖健康领域的深度理解与精耕细作，依托“生殖健康供应链综合服务平台”的渠道优势，以医疗服务为中心节点，以专家智慧和人工智能为价值枢纽，链接先进生命医学技术，打造生殖健康功能网，协同科技与人性，赋能生命。专业护航，让生命传承。锦奇医疗秉持“回归医疗本质，以用户为中心”的服务理念,以“专业、专注、守正、利他”的核心价值理念，链接信息、整合国内外先进的生殖医疗科技，与国内外各大医疗机构及产业链中的各个供应商合作，构建生殖医疗完整的生态系统。同时联动国内外生殖专家，打破时间、空间限制，将用户、医院、机构链接起来，形成“诊前诊中诊后一体、线上线下互动、院内院外互通”的新型智慧医疗服务模式，为备孕、优孕、不孕用户提供整体生育解决方案和全生命周期管理。从备孕到优孕、从生殖到生命好孕未来，绽放“生”之美好", //公司简介
        contacts: "朱攀", //联系人
        telephone: "13007142502", //联系电话
        id: 11,
    },
    {
        postName: "医生合伙人", //岗位名称
        jobDescription:
            "工作职责: 1、负责医院医生的洽谈与APP推广工作;2、负责长期维护和医生的业务关系;3、负责与医生传递企业理念及价值;4、协调匹配专家出诊;5、建立专家档案及与各部门协调配合各项业务工作等。6；此岗位为兼职 介意勿扰", //岗位描述
        postSalary: "5-10K", //岗位薪资
        educationalRequirements: "高中", //学历要求
        workingYears: "1-3年", //工作年限
        corporateName: "锦奇（上海）医疗投资有限公司", //公司名称
        companyAddress: "上海长宁区中关村·虹桥创新中心南7座7座10楼", //公司地址
        companyResume:
            "锦奇医疗创立于2007年，致力于生殖健康服务，是集实体医疗机构、互联网医院、海外生殖医疗服务、医药流通、医药研发、器械、检验及进口保健品生产与销售一体的国际化生殖医疗集团。基于对生殖健康领域的深度理解与精耕细作，依托“生殖健康供应链综合服务平台”的渠道优势，以医疗服务为中心节点，以专家智慧和人工智能为价值枢纽，链接先进生命医学技术，打造生殖健康功能网，协同科技与人性，赋能生命。专业护航，让生命传承。锦奇医疗秉持“回归医疗本质，以用户为中心”的服务理念,以“专业、专注、守正、利他”的核心价值理念，链接信息、整合国内外先进的生殖医疗科技，与国内外各大医疗机构及产业链中的各个供应商合作，构建生殖医疗完整的生态系统。同时联动国内外生殖专家，打破时间、空间限制，将用户、医院、机构链接起来，形成“诊前诊中诊后一体、线上线下互动、院内院外互通”的新型智慧医疗服务模式，为备孕、优孕、不孕用户提供整体生育解决方案和全生命周期管理。从备孕到优孕、从生殖到生命好孕未来，绽放“生”之美好", //公司简介
        contacts: "朱攀", //联系人
        telephone: "13007142502", //联系电话
        id: 12,
    },
    {
        postName: "医疗器械销售（长沙）", //岗位名称
        jobDescription:
            "工作职责: 1、负责所辖区域目标医院开发工作与维护；2、积极推进学术推广计划，负责完成目标医院的各项指标；3、专业化的运用学术推广完成所辖目标医院专家的建设与维护，与目标医院及医生保持良好关系；4、完成公司及上级安排的其它工作。职位要求：1、大专以上学历，药品，医疗器械相关专业优先；2、优秀毕业生或有妇产科医院资源优先；3、有上进心，沟通能力强；4、吃苦耐劳，具有良好的学习能力和团队合作精神；5、注重个人仪表，做事注重细节并且持之以恒", //岗位描述
        postSalary: "12-23K", //岗位薪资
        educationalRequirements: "大专", //学历要求
        workingYears: "经验不限", //工作年限
        corporateName: "锦奇（上海）医疗投资有限公司", //公司名称
        companyAddress: "上海长宁区中关村·虹桥创新中心南7座7座10楼", //公司地址
        companyResume:
            "锦奇医疗创立于2007年，致力于生殖健康服务，是集实体医疗机构、互联网医院、海外生殖医疗服务、医药流通、医药研发、器械、检验及进口保健品生产与销售一体的国际化生殖医疗集团。基于对生殖健康领域的深度理解与精耕细作，依托“生殖健康供应链综合服务平台”的渠道优势，以医疗服务为中心节点，以专家智慧和人工智能为价值枢纽，链接先进生命医学技术，打造生殖健康功能网，协同科技与人性，赋能生命。专业护航，让生命传承。锦奇医疗秉持“回归医疗本质，以用户为中心”的服务理念,以“专业、专注、守正、利他”的核心价值理念，链接信息、整合国内外先进的生殖医疗科技，与国内外各大医疗机构及产业链中的各个供应商合作，构建生殖医疗完整的生态系统。同时联动国内外生殖专家，打破时间、空间限制，将用户、医院、机构链接起来，形成“诊前诊中诊后一体、线上线下互动、院内院外互通”的新型智慧医疗服务模式，为备孕、优孕、不孕用户提供整体生育解决方案和全生命周期管理。从备孕到优孕、从生殖到生命好孕未来，绽放“生”之美好", //公司简介
        contacts: "朱攀", //联系人
        telephone: "13007142502", //联系电话
        id: 13,
    },
    {
        postName: "妇产科医药代表（全江苏）", //岗位名称
        jobDescription:
            "工作职责: 1、负责医院医生的洽谈与APP推广工作2、负责药品在线上销售职位要求：1、有生殖领域、妇产科领域医生资源。与以上领域医生有良好的合作关系。2、兼职工作", //岗位描述
        postSalary: "10-15K", //岗位薪资
        educationalRequirements: "学历不限", //学历要求
        workingYears: "经验不限", //工作年限
        corporateName: "锦奇（上海）医疗投资有限公司", //公司名称
        companyAddress: "上海长宁区中关村·虹桥创新中心南7座7座10楼", //公司地址
        companyResume:
            "锦奇医疗创立于2007年，致力于生殖健康服务，是集实体医疗机构、互联网医院、海外生殖医疗服务、医药流通、医药研发、器械、检验及进口保健品生产与销售一体的国际化生殖医疗集团。基于对生殖健康领域的深度理解与精耕细作，依托“生殖健康供应链综合服务平台”的渠道优势，以医疗服务为中心节点，以专家智慧和人工智能为价值枢纽，链接先进生命医学技术，打造生殖健康功能网，协同科技与人性，赋能生命。专业护航，让生命传承。锦奇医疗秉持“回归医疗本质，以用户为中心”的服务理念,以“专业、专注、守正、利他”的核心价值理念，链接信息、整合国内外先进的生殖医疗科技，与国内外各大医疗机构及产业链中的各个供应商合作，构建生殖医疗完整的生态系统。同时联动国内外生殖专家，打破时间、空间限制，将用户、医院、机构链接起来，形成“诊前诊中诊后一体、线上线下互动、院内院外互通”的新型智慧医疗服务模式，为备孕、优孕、不孕用户提供整体生育解决方案和全生命周期管理。从备孕到优孕、从生殖到生命好孕未来，绽放“生”之美好", //公司简介
        contacts: "朱攀", //联系人
        telephone: "13007142502", //联系电话
        id: 14,
    },
    {
        postName: "商务司机", //岗位名称
        jobDescription:
            "工作职责: 1、负责车辆驾驶，服务对象为公司高层领导；2、保持车辆整洁，维护保养，使车辆随时处于良好状态；3、负责领导日常接送、客户拜访、会议应酬等。岗位要求：1、35岁以下，中专以上学历，3年驾龄以上，持C1以上驾照；2、身体健康、有良好的品行和驾驶技术，无违法犯罪记录，无重大交通事故；3、服务意识强，可接受临时出车", //岗位描述
        postSalary: "6-7K", //岗位薪资
        educationalRequirements: "高中", //学历要求
        workingYears: "1-3年", //工作年限
        corporateName: "锦奇（上海）医疗投资有限公司", //公司名称
        companyAddress: "上海长宁区中关村·虹桥创新中心南7座7座10楼", //公司地址
        companyResume:
            "锦奇医疗创立于2007年，致力于生殖健康服务，是集实体医疗机构、互联网医院、海外生殖医疗服务、医药流通、医药研发、器械、检验及进口保健品生产与销售一体的国际化生殖医疗集团。基于对生殖健康领域的深度理解与精耕细作，依托“生殖健康供应链综合服务平台”的渠道优势，以医疗服务为中心节点，以专家智慧和人工智能为价值枢纽，链接先进生命医学技术，打造生殖健康功能网，协同科技与人性，赋能生命。专业护航，让生命传承。锦奇医疗秉持“回归医疗本质，以用户为中心”的服务理念,以“专业、专注、守正、利他”的核心价值理念，链接信息、整合国内外先进的生殖医疗科技，与国内外各大医疗机构及产业链中的各个供应商合作，构建生殖医疗完整的生态系统。同时联动国内外生殖专家，打破时间、空间限制，将用户、医院、机构链接起来，形成“诊前诊中诊后一体、线上线下互动、院内院外互通”的新型智慧医疗服务模式，为备孕、优孕、不孕用户提供整体生育解决方案和全生命周期管理。从备孕到优孕、从生殖到生命好孕未来，绽放“生”之美好", //公司简介
        contacts: "朱攀", //联系人
        telephone: "13007142502", //联系电话
        id: 15,
    },
    {
        postName: "连云港妇产科医药代表", //岗位名称
        jobDescription:
            "工作职责: 1、负责医院医生的洽谈与APP推广工作；2、负责药品在线上销售职位要求：1、有生殖领域、妇产科领域医生资源。与以上领域医生有良好的合作关系。2、兼职工作", //岗位描述
        postSalary: "10-15K", //岗位薪资
        educationalRequirements: "学历不限", //学历要求
        workingYears: "经验不限", //工作年限
        corporateName: "锦奇（上海）医疗投资有限公司", //公司名称
        companyAddress: "上海长宁区中关村·虹桥创新中心南7座7座10楼", //公司地址
        companyResume:
            "锦奇医疗创立于2007年，致力于生殖健康服务，是集实体医疗机构、互联网医院、海外生殖医疗服务、医药流通、医药研发、器械、检验及进口保健品生产与销售一体的国际化生殖医疗集团。基于对生殖健康领域的深度理解与精耕细作，依托“生殖健康供应链综合服务平台”的渠道优势，以医疗服务为中心节点，以专家智慧和人工智能为价值枢纽，链接先进生命医学技术，打造生殖健康功能网，协同科技与人性，赋能生命。专业护航，让生命传承。锦奇医疗秉持“回归医疗本质，以用户为中心”的服务理念,以“专业、专注、守正、利他”的核心价值理念，链接信息、整合国内外先进的生殖医疗科技，与国内外各大医疗机构及产业链中的各个供应商合作，构建生殖医疗完整的生态系统。同时联动国内外生殖专家，打破时间、空间限制，将用户、医院、机构链接起来，形成“诊前诊中诊后一体、线上线下互动、院内院外互通”的新型智慧医疗服务模式，为备孕、优孕、不孕用户提供整体生育解决方案和全生命周期管理。从备孕到优孕、从生殖到生命好孕未来，绽放“生”之美好", //公司简介
        contacts: "朱攀", //联系人
        telephone: "13007142502", //联系电话
        id: 16,
    },
    {
        postName: "产品经理（医药行业）", //岗位名称
        jobDescription:
            "工作职责: 1.参与重点项目的系列产品规划，并提出响应的设计方案、竞品分析、需求整理；2.针对各需求部门、用户体验，收集并输出产品优化方案；3.根据需求输出高质量的产品文档、流程原型图；4.协同UE、UI人员输出高质量的交互、视觉稿；5.负责迭代过程中的资源协调，与运营、研发团队紧密配合，推动项目迭代有序高效运转，对产品质量负责。职位要求：1.本科以上学历，3-5年以上的互联网产品工作经验，有在线产品者优先；2.较强的战略思考能力和产品决策能力；3.具备较好的产品设计理念和严谨的逻辑思维；4.待人接物简单直接，有强烈的学习欲望，对市场和用户保持好奇心。", //岗位描述
        postSalary: "15-22K", //岗位薪资
        educationalRequirements: "大专", //学历要求
        workingYears: "1-3年", //工作年限
        corporateName: "锦奇（上海）医疗投资有限公司", //公司名称
        companyAddress: "上海长宁区中关村·虹桥创新中心南7座7座10楼", //公司地址
        companyResume:
            "锦奇医疗创立于2007年，致力于生殖健康服务，是集实体医疗机构、互联网医院、海外生殖医疗服务、医药流通、医药研发、器械、检验及进口保健品生产与销售一体的国际化生殖医疗集团。基于对生殖健康领域的深度理解与精耕细作，依托“生殖健康供应链综合服务平台”的渠道优势，以医疗服务为中心节点，以专家智慧和人工智能为价值枢纽，链接先进生命医学技术，打造生殖健康功能网，协同科技与人性，赋能生命。专业护航，让生命传承。锦奇医疗秉持“回归医疗本质，以用户为中心”的服务理念,以“专业、专注、守正、利他”的核心价值理念，链接信息、整合国内外先进的生殖医疗科技，与国内外各大医疗机构及产业链中的各个供应商合作，构建生殖医疗完整的生态系统。同时联动国内外生殖专家，打破时间、空间限制，将用户、医院、机构链接起来，形成“诊前诊中诊后一体、线上线下互动、院内院外互通”的新型智慧医疗服务模式，为备孕、优孕、不孕用户提供整体生育解决方案和全生命周期管理。从备孕到优孕、从生殖到生命好孕未来，绽放“生”之美好", //公司简介
        contacts: "朱攀", //联系人
        telephone: "13007142502", //联系电话
        id: 17,
    },
]
export default talentData