<!--  -->
<template>
  <div class="home-conter">
    <div class="fly">客服热线：400-182-6969</div>
    <el-tabs v-model="activeName" @tab-click="handleClick(activeName)">
      <el-tab-pane label="首页" name="0">
        <div class="tab-title-name">
          <p>热门推荐</p>
          <p></p>
        </div>
        <!-- 首页 -->
        <div class="tab-conent-card" v-if="activeName === '0'">
          <el-card
            class="box-card"
            shadow="hover"
            v-for="(val, index) in newTalentData.slice(
              (currentPage - 1) * pagesize,
              currentPage * pagesize
            )"
            :key="index"
            @click.native="detailHomeCard(val)"
          >
            <div class="tab-conent-card-top">
              <p>{{ val.postName }}</p>
              <p>{{ val.postSalary }}</p>
            </div>
            <div class="tab-conent-card-time">
              {{ val.companyAddress }} | {{ val.workingYears }} |
              {{ val.educationalRequirements }}
            </div>
            <div
              style="
                width: 344px;
                height: 1px;
                background: rgba(48, 46, 55, 0.1);
                margin: 16px 0 15px 0;
              "
            ></div>
            <div class="tab-conent-card-company">
              {{ val.corporateName }}
            </div>
          </el-card>
        </div>
        <!-- 分页 -->
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pagesize"
            layout="jumper, prev, pager, next"
            :total="talentData.length"
            background
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="找工作" name="1">
        <!-- 找工作 -->
        <div class="tab-title-name">
          <p>岗位列表</p>
          <p></p>
        </div>
        <div>
          <el-card
            class="new-box-cards"
            v-for="(val, index) in newTalentData.slice(
              (currentPage - 1) * pagesize,
              currentPage * pagesize
            )"
            :key="index"
            shadow="hover"
            @click.native="detailTalentCard(val)"
          >
            <div class="cards-top">
              <p>{{ val.postName }}</p>
              <p>{{ val.postSalary }}</p>
            </div>
            <div class="card-company">
              {{ val.corporateName }} | {{ val.companyAddress }} |
              {{ val.workingYears }} | {{ val.educationalRequirements }}
            </div>
          </el-card>
          <!-- 分页 -->
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pagesize"
              layout="jumper, prev, pager, next"
              :total="talentData.length"
              background
            >
            </el-pagination>
          </div>
        </div>
        <!-- 三级 -->
        <router-view />
      </el-tab-pane>
      <el-tab-pane label="招人才" name="2">
        <!-- 招人才 -->
        <div class="tab-title-name">
          <p>人才列表</p>
          <p></p>
        </div>
        <div>
          <el-card
            class="new-box-cards"
            v-for="(val, index) in newPersonnelData.slice(
              (currentPage - 1) * pagesize,
              currentPage * pagesize
            )"
            :key="index"
            shadow="hover"
            @click.native="detailsPersonnelCard(val)"
          >
            <div class="cards-top">
              <p>{{ val.postName }}</p>
              <p>{{ val.salaryExpectation }}</p>
            </div>
            <div class="card-company">
              {{ val.city }} | {{ val.workingYears }} |
              {{ val.yourEducation }}
            </div>
          </el-card>
          <!-- 分页 -->
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pagesize"
              layout="jumper, prev, pager, next"
              :total="personnelData.length"
              background
            >
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="信息发布" name="3">
        <!-- 信息发布 -->
        <div class="tab-title-name">
          <p>信息发布</p>
          <p></p>
        </div>
        <div class="button-tab" v-if="activeName === '3'">
          <div>
            <el-button @click="curHand('0')" :class="{ active: cur == '0' }"
              >我要招人才</el-button
            >
            <el-button @click="curHand('1')" :class="{ active: cur == '1' }"
              >我要找工作</el-button
            >
          </div>
          <div class="show">
            <div class="topnav-show" v-show="cur == '0'">
              <el-form
                :model="personnelRuleForm"
                :rules="recruitTalentsRules"
                ref="personnelRuleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="岗位名称" prop="postName">
                  <el-input
                    v-model="personnelRuleForm.postName"
                    style="width: 540px"
                    placeholder="请输入岗位名称"
                  ></el-input>
                </el-form-item>

                <el-form-item label="岗位描述" prop="jobDescription">
                  <el-input
                    v-model="personnelRuleForm.jobDescription"
                    style="width: 540px"
                    type="textarea"
                    placeholder="请输入岗位描述"
                  ></el-input>
                </el-form-item>

                <el-form-item label="岗位薪资" prop="postSalary">
                  <el-input
                    v-model="personnelRuleForm.postSalary"
                    style="width: 540px"
                    placeholder="请输入岗位薪资（列如6000元/月）"
                  ></el-input>
                </el-form-item>
                <el-form-item label="学历要求" prop="educationalRequirements">
                  <el-input
                    v-model="personnelRuleForm.educationalRequirements"
                    style="width: 540px"
                    placeholder="请输入学历要求"
                  ></el-input>
                </el-form-item>

                <el-form-item label="工作年限" prop="workingYears">
                  <el-input
                    v-model="personnelRuleForm.workingYears"
                    style="width: 540px"
                    placeholder="请输入工作年限"
                  ></el-input>
                </el-form-item>
                <el-form-item label="公司名称" prop="corporateName">
                  <el-input
                    v-model="personnelRuleForm.corporateName"
                    style="width: 540px"
                    placeholder="请输入公司名称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="公司地址" prop="companyAddress">
                  <el-input
                    v-model="personnelRuleForm.companyAddress"
                    style="width: 540px"
                    placeholder="请输入公司地址"
                  ></el-input>
                </el-form-item>
                <el-form-item label="公司简介" prop="companyResume">
                  <el-input
                    v-model="personnelRuleForm.companyResume"
                    style="width: 540px"
                    type="textarea"
                    placeholder="请输入公司简介"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="contacts">
                  <el-input
                    v-model="personnelRuleForm.contacts"
                    style="width: 540px"
                    placeholder="请输入联系人"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="telephone">
                  <el-input
                    v-model="personnelRuleForm.telephone"
                    style="width: 540px"
                    placeholder="请输入联系电话"
                  ></el-input>
                </el-form-item>

                <el-form-item>
                  <el-button
                    type="primary"
                    style="
                      width: 268px;
                      height: 40px;
                      background: #ff7d3e;
                      border: none;
                      margin: 15px 0 0 100px;
                    "
                    @click="submitForm('personnelRuleForm', personnelRuleForm)"
                    >提交</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
            <div class="topnav-show" v-show="cur == '1'">
              <el-form
                :model="jobHuntingForm"
                :rules="jobHuntingRules"
                ref="jobHuntingRuleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="岗位名称" prop="postName">
                  <el-input
                    v-model="jobHuntingForm.postName"
                    style="width: 540px"
                    placeholder="请输入岗位名称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="岗位描述" prop="jobDescription">
                  <el-input
                    v-model="jobHuntingForm.jobDescription"
                    style="width: 540px"
                    placeholder="请输入岗位描述"
                    type="textarea"
                  ></el-input>
                </el-form-item>
                <el-form-item label="期望薪资" prop="salaryExpectation">
                  <el-input
                    v-model="jobHuntingForm.salaryExpectation"
                    style="width: 540px"
                    placeholder="请输入期望薪资（列如6000元/月）"
                  ></el-input>
                </el-form-item>
                <el-form-item label="你的学历" prop="yourEducation">
                  <el-input
                    v-model="jobHuntingForm.yourEducation"
                    style="width: 540px"
                    placeholder="请输入你的学历"
                  ></el-input>
                </el-form-item>
                <el-form-item label="工作年限" prop="workingYears">
                  <el-input
                    v-model="jobHuntingForm.workingYears"
                    style="width: 540px"
                    placeholder="请填写工作年限"
                  ></el-input>
                </el-form-item>

                <el-form-item label="所在城市" prop="city">
                  <el-input
                    v-model="jobHuntingForm.city"
                    style="width: 540px"
                    placeholder="请填写所在城市"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="contacts">
                  <el-input
                    v-model="jobHuntingForm.contacts"
                    style="width: 540px"
                    placeholder="请填写联系人"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="contactNumber">
                  <el-input
                    v-model="jobHuntingForm.contactNumber"
                    style="width: 540px"
                    placeholder="请填写联系电话"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    style="
                      width: 268px;
                      height: 40px;
                      background: #ff7d3e;
                      border: none;
                      margin: 15px 0 0 100px;
                    "
                    @click="jobSubmitForm('jobHuntingRuleForm', jobHuntingForm)"
                    >提交</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import postList from "../../../../mock/work"; //岗位信息数据
import personnelList from "../../../../mock/personnel"; //人才列表数据
export default {
  data() {
    //手机号正则校验
    var isMobileNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填写联系电话"));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); //转换为数字
        if (typeof value === "number" && !isNaN(value)) {
          //判断是否为数字
          value = value.toString(); //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            //判断是否为11位手机号
            callback(new Error("请输入正确的联系电话"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请输入联系电话"));
        }
      }
    };
    //手机号正则校验
    var telephone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填写联系电话"));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); //转换为数字
        if (typeof value === "number" && !isNaN(value)) {
          //判断是否为数字
          value = value.toString(); //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            //判断是否为11位手机号
            callback(new Error("请输入正确的联系电话"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请输入联系电话"));
        }
      }
    };
    return {
      currentPage: 1,
      pagesize: 15,
      totalPage: 1,
      activeName: "0",
      activeInformation: "first",
      cur: "0",
      //招人才
      personnelRuleForm: {
        postName: "", //岗位名称
        jobDescription: "", //岗位描述
        postSalary: "", //岗位薪资
        educationalRequirements: "", //学历要求
        workingYears: "", //工作年限
        corporateName: "", //公司名称
        companyAddress: "", //公司地址
        companyResume: "", //公司简介
        contacts: "", //联系人
        telephone: "", //联系电话
      },
      //找工作
      jobHuntingForm: {
        postName: "", //岗位名称
        jobDescription: "", //岗位描述
        salaryExpectation: "", //期望薪资
        yourEducation: "", //你的学历
        workingYears: "", //工作年限
        city: "", //所在城市
        contacts: "", //联系人
        contactNumber: "", //联系电话
      },

      //招人才的校验
      recruitTalentsRules: {
        postName: [
          { required: true, message: "请填写岗位名称", trigger: "blur" },
        ],
        jobDescription: [
          { required: true, message: "请填写岗位描述", trigger: "blur" },
        ],
        postSalary: [
          { required: true, message: "请填写岗位薪资", trigger: "blur" },
        ],
        educationalRequirements: [
          { required: true, message: "请填写学历要求", trigger: "blur" },
        ],
        workingYears: [
          { required: true, message: "请填写工作年限", trigger: "blur" },
        ],
        corporateName: [
          { required: true, message: "请填写公司名称", trigger: "blur" },
        ],
        companyAddress: [
          { required: true, message: "请填写公司地址", trigger: "blur" },
        ],
        companyResume: [
          { required: true, message: "请填写公司简介", trigger: "blur" },
        ],
        contacts: [
          { required: true, message: "请填写联系人", trigger: "blur" },
        ],
        telephone: [{ validator: telephone, trigger: "blur", required: true }],
      },
      //找工作的校验
      jobHuntingRules: {
        postName: [
          { required: true, message: "请填写岗位名称", trigger: "blur" },
        ],
        jobDescription: [
          { required: true, message: "请填写岗位描述", trigger: "blur" },
        ],
        salaryExpectation: [
          { required: true, message: "请填写期望薪资", trigger: "blur" },
        ],
        yourEducation: [
          { required: true, message: "请填写你的学历", trigger: "blur" },
        ],
        workingYears: [
          { required: true, message: "请填写工作年限", trigger: "blur" },
        ],
        city: [{ required: true, message: "请填写所在城市", trigger: "blur" }],
        contacts: [
          { required: true, message: "请填写联系人", trigger: "blur" },
        ],
        contactNumber: [
          { validator: isMobileNumber, trigger: "blur", required: true },
        ],
      },
      //岗位信息
      talentData: postList,
      //招人才数据
      personnelData: personnelList,
      newTalentData: [],
      newPersonnelData: [],
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    localStorage.getItem("talentData")
      ? ""
      : localStorage.setItem("talentData", JSON.stringify(this.talentData));

    this.newTalentData = JSON.parse(localStorage.getItem("talentData"));

    localStorage.getItem("personnelData")
      ? ""
      : localStorage.setItem(
          "personnelData",
          JSON.stringify(this.personnelData)
        );
    this.newPersonnelData = JSON.parse(localStorage.getItem("personnelData"));

    this.activeName = localStorage.getItem("activeName")
      ? localStorage.getItem("activeName")
      : "0";
    localStorage.removeItem("activeName");
    //详情页退回保存当前分页页数
    this.currentPage=sessionStorage.getItem('pages')?Number(sessionStorage.getItem('pages')):Number(1)

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      sessionStorage.setItem('pages',val)
    },

    handleClick(activeName) {
      this.currentPage = 1;
      if (activeName === "3") {
        const token = localStorage.getItem("token");
        if (!token) {
          sessionStorage.setItem("type", "1");
          this.$router.push({ path: "/login" });
        }
      }
      // 重置页面滚动条位置
      window.scrollTo(0, 0)
    },
    //tab点击
    curHand(cur) {
      this.cur = cur;
      const personnelRuleForm = "personnelRuleForm";
      const jobHuntingRuleForm = "jobHuntingRuleForm";
      this.$refs[personnelRuleForm].resetFields();
      this.$refs[jobHuntingRuleForm].resetFields();
    },
    //招人才
    submitForm(personnelRuleForm, data) {
      this.$refs[personnelRuleForm].validate((valid) => {
        if (valid) {
          const index = JSON.parse(localStorage.getItem("talentData"));
          const newData = {
            postName: data.postName,
            jobDescription: data.jobDescription,
            postSalary: data.postSalary,
            educationalRequirements: data.educationalRequirements,
            workingYears: data.workingYears,
            corporateName: data.corporateName,
            companyAddress: data.companyAddress,
            companyResume: data.companyResume,
            contacts: data.contacts,
            telephone: data.telephone,
            id: (index.length = index.length + 1),
          };
          this.newTalentData.unshift(newData);
          localStorage.setItem(
            "talentData",
            JSON.stringify(this.newTalentData)
          );
          //   this.$refs[personnelRuleForm].resetFields();
          Object.keys(this.personnelRuleForm).forEach(
            (key) => (this.personnelRuleForm[key] = "")
          ); //清除输入框
          this.$message({
            message: "提交成功",
            type: "success",
          });
        } else {
          return false;
        }
      });
    },
    //找工作
    jobSubmitForm(jobHuntingRuleForm, data) {
      this.$refs[jobHuntingRuleForm].validate((valid) => {
        if (valid) {
          const index = JSON.parse(localStorage.getItem("personnelData"));
          const newData = {
            postName: data.postName,
            jobDescription: data.jobDescription,
            salaryExpectation: data.salaryExpectation,
            yourEducation: data.yourEducation,
            workingYears: data.workingYears,
            city: data.city,
            contacts: data.contacts,
            contactNumber: data.contactNumber,
            id: (index.length = index.length + 1),
          };
          this.newPersonnelData.unshift(newData);

          localStorage.setItem(
            "personnelData",
            JSON.stringify(this.newPersonnelData)
          );
          this.$message({
            message: "提交成功",
            type: "success",
          });
          Object.keys(this.jobHuntingForm).forEach(
            (key) => (this.jobHuntingForm[key] = "")
          ); //清空输入框
        } else {
          return false;
        }
      });
    },
    //首页详情
    detailHomeCard(data) {
      this.$router.push({
        path: "/home/workplaceInformation/workDetail",
        query: { id: data.id },
      });
      localStorage.setItem("activeName", "0");
      // 重置页面滚动条位置
      window.scrollTo(0, 0)
    },
    //找工作详情
    detailTalentCard(data) {
      this.$router.push({
        path: "/home/workplaceInformation/workDetail",
        query: { id: data.id },
      });
      localStorage.setItem("activeName", "1");
      // 重置页面滚动条位置
      window.scrollTo(0, 0)
    },
    //招人才详情
    detailsPersonnelCard(data) {
      this.$router.push({
        path: "/home/workplaceInformation/personnelDetail",
        query: { id: data.id },
      });
      localStorage.setItem("activeName", "2");
      // 重置页面滚动条位置
      window.scrollTo(0, 0)
    },
  },
};
</script>
<style lang='scss' scoped>
.home-conter {
  max-width: 1920px;
  //   min-height: 941px;
  background: #f5f7fa;
  padding-bottom: 50px;
  position: relative;
}
::v-deep .el-tabs {
  max-width: 1920px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #f5f7fa;
}
::v-deep .el-tabs__header {
  width: 100%;
  min-height: 50px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  position: sticky;
  top: 76px;
  z-index: 99;
  //   .el-tabs__nav-wrap {
  //     margin-left: 10%;
  //   }
}
::v-deep .el-tabs__nav-wrap {
  min-width: 1184px;
  min-height: 50px;
}
::v-deep .el-tabs__nav-scroll {
  max-width: 1184px;
  min-height: 50px;
  display: flex;
  align-items: center;
  margin-left: 29px;
}
::v-deep .el-tabs__item {
  padding: 0px 64px;
}
::v-deep .el-tabs__item.is-active {
  //切换活动项的文字颜色
  width: auto;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 500;
  color: #ff7d3e;
  line-height: 22px;
}
::v-deep .el-tabs__item:hover {
  color: #ff7d3e;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 0;
}
::v-deep .el-tabs__active-bar {
  background-color: #ff7d3e !important; //切换活动项的长条颜色
  height: 3px;
}
::v-deep .el-tabs__content {
  max-width: 1184px;
  //   min-height: 941px;
  background: #f5f7fa;
  display: flex;
  justify-content: center;
  //   text-align: center;
}
::v-deep .el-tab-pane {
  min-width: 1184px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
}
//tab内容样式
.tab-title-name {
  margin-top: 26px;
  > :nth-child(1) {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(48, 46, 55, 0.7);
    line-height: 20px;
  }
  > :nth-child(2) {
    width: 1184px;
    height: 1px;
    background: rgba(48, 46, 55, 0.1);
    margin: 8px 0px 16px 0px;
  }
}
//卡片
.tab-conent-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  
}

.box-card {
  width: 384px;
  height: 140px;
  background: #ffffff;
  border: 1px solid #ffffff;
  margin-bottom: 16px;
  margin-right: 8px;
  margin-left: 2px;
}
.tab-conent-card-top {
  display: flex;
  justify-content: space-between;
  :nth-child(1) {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #302e37;
    line-height: 22px;
  }
  :nth-child(2) {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff7d3e;
    line-height: 22px;
  }
}
.tab-conent-card-time {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(48, 46, 55, 0.4);
  line-height: 20px;
  margin-top: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tab-conent-card-company {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(48, 46, 55, 0.7);
  line-height: 20px;
}
//人才招聘，卡片
.new-box-cards {
  z-index: 9999;
  width: 1184px;
  height: 90px;
  background: #ffffff;
  border: 1px solid #ffffff;
  margin-bottom: 10px;
  .cards-top {
    display: flex;
    justify-content: space-between;
    :nth-child(1) {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #302e37;
      line-height: 22px;
    }
    :nth-child(2) {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff7d3e;
      line-height: 22px;
    }
  }
  .card-company {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(48, 46, 55, 0.4);
    line-height: 20px;
    margin-top: 14px;
  }
}
//tab切换2
.button-tab {
  max-width: 1184px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  > div > button {
    margin-top: 40px;
    width: 160px;
    height: 50px;
    background: #ffffff;
    border: 1px solid rgba(48, 46, 55, 0.2);
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(48, 46, 55, 0.7);
    line-height: 22px;
  }
}
//高亮
.button-tab > div > button.active {
  width: 160px;
  height: 50px;
  background: #ff7d3e;
  border: 1px solid #ff7d3e;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 22px;
}
//内容
.show {
  margin-top: 30px;
  max-width: 1184px;
}
//首页分页
.block {
  text-align: center;
  margin-top: 30px;
}
::v-deep .el-textarea{
  height: 100px;
}
::v-deep .el-textarea__inner{
  height: 100px;
}
//客服电话
.fly {
  position: absolute;
  left: 72%;
  top: 13px;
  z-index: 100;
  width: 169px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(48, 46, 55, 0.5);
  line-height: 20px;
}
</style>