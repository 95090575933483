const personnel = [
    {
        postName: "网络销售", //岗位名称
        jobDescription: "网络销售+双休五险一金", //岗位描述
        salaryExpectation: "8K", //期望薪资
        yourEducation: "本科", //你的学历
        workingYears: "3-5年", //工作年限
        city: "湖南长沙", //所在城市
        contacts: "张静", //联系人
        contactNumber: "13107187901", //联系电话
        id: 1,
    },
    {
        postName: "IOS开发工程师", //岗位名称
        jobDescription: "我会IOS开发，想要找一份工作", //岗位描述
        salaryExpectation: "13-20K", //期望薪资
        yourEducation: "大专", //你的学历
        workingYears: "1-3年", //工作年限
        city: "湖北武汉", //所在城市
        contacts: "朱攀", //联系人
        contactNumber: "13007142502", //联系电话
        id: 2,
    },
    {
        postName: "互联网医助", //岗位名称
        jobDescription: "工作职责：1.主要负责医生资料审核、线上用户医学问题的解答等；2.帮助用户发现问题，解决问题；3.用户端详细问题的解答，整理用户资料，疾病档案的归类。任职要求：1、大专以上学历，2年左右服务行业工作优先；2、学习能力强，有耐心有服务意识，擅长沟通协调；3、医学，护理学专业或医助优先。", //岗位描述
        salaryExpectation: "7-10K", //期望薪资
        yourEducation: "大专", //你的学历
        workingYears: "1-3年", //工作年限
        city: "江苏南京", //所在城市
        contacts: "王赛风", //联系人
        contactNumber: "18982768901", //联系电话
        id: 3,
    },
    {
        postName: "超市营业员", //岗位名称
        jobDescription: "想要找一份超市营业员工作，有些经验", //岗位描述
        salaryExpectation: "6-10K", //期望薪资
        yourEducation: "中专/中技", //你的学历
        workingYears: "1-3年", //工作年限
        city: "江苏南京", //所在城市
        contacts: "朱攀", //联系人
        contactNumber: "13007142502", //联系电话
        id: 4,
    },
    {
        postName: "培训主管/培训经理", //岗位名称
        jobDescription: "我会：1.根据公司规划及部门相关需求，搭建人才培养体系，并负责推动落地;2.根据公司的发展规划做好培训部门的年度培训计划并组织实施;3.组织内训讲师团队的建设、进行培训课程的开发、收集与整理培训课程所需的资料;4.组织开发企业内部培训课程体系，降低培训成本，提升企业内部培训水平;5.协助各部门开展培训工作，做好培训跟进、指导和监管工作6.公众号运营", //岗位描述
        salaryExpectation: "15-25K·13薪", //期望薪资
        yourEducation: "高中", //你的学历
        workingYears: "1-3年", //工作年限
        city: "江苏连云港", //所在城市
        contacts: "于高鹏", //联系人
        contactNumber: "19872809865", //联系电话
        id: 5,
    },
    {
        postName: "优化经理", //岗位名称
        jobDescription: "1、通过今日头条、广点通、DSP等平台投放和优化手游产品，达成KPI考核，提升消耗，对结果负责；2、基于投放数据分析，进行创意迭代，与设计师密切协作，产出高质量创意；3、筛选组合定投的媒体资源，有节奏的探测流量效果，挖掘匹配产品目标用户的价值流量；4、带领团队进行重难点case的优化效果突破，梳理提炼手游优化的有效手法；5、基于整体业务目标，优化团队协作机制，不断提升产能；6、对内外沟通，与客户、销售、AE、运营建立长期稳定的沟通，默契协作", //岗位描述
        salaryExpectation: "18-30K", //期望薪资
        yourEducation: "大专", //你的学历
        workingYears: "1-3年", //工作年限
        city: "上海静安", //所在城市
        contacts: "吴砍", //联系人
        contactNumber: "1567898201", //联系电话
        id: 6,
    },
    {
        postName: "电商运营推广", //岗位名称
        jobDescription: "1.根据品牌月度/年度销售目标进行相关流量目标拆解及相关计划的制定，并组织实施落地；2.通过对历史及店铺当下数据的分析，制定站内费用投入预算等计划，制定各渠道费用分配、投放人群策略、各SKU投放费用占比等；3.负责主流渠道：天猫旗舰店的付费流量的推广计划执行并对ROI负责；4.负责钻展、直通车和超级推荐等实操工作的执行落地，并不断优化投放计划；5.负责大小促期间搜索关键词的优化及流量的引入计划，定期提交推广报告及推广效果分析；6.部分站外投放资源的沟通及对接，承接品牌流量需求，控制预算和ROI，提高转化率；", //岗位描述
        salaryExpectation: "15-25K·13薪", //期望薪资
        yourEducation: "高中", //你的学历
        workingYears: "1-3年", //工作年限
        city: "上海静安", //所在城市
        contacts: "刘天", //联系人
        contactNumber: "18902876543", //联系电话
        id: 7,
    },
    {
        postName: "信息流广告优化师", //岗位名称
        jobDescription: "1、负责广告平台上线投放及文案撰写、日报、日常客户维护沟通。2、对广告素材、投放数据、活动页面进行分析总结，为客户产品的上线需求明确思路，把控广告素材制作进度，与产品团队协作设计制定素材；3、跟进广告投放进度，提高广告的曝光量、点击率等数据，对媒体质量进行把控；4、监测渠道效果，控制投放风险，处理媒体及客户的相关工作；5、负责广告投放计划的制定及广告效果分析、评估，通过投放数据有效的对投放渠道进行优化，降低投放成本，保证利润；6、根据日常工作，可总结流程及输出方法论7、随时协助和执行上级分配的工作。", //岗位描述
        salaryExpectation: "12-22K", //期望薪资
        yourEducation: "学历不限", //你的学历
        workingYears: "3-5年", //工作年限
        city: "上海静安", //所在城市
        contacts: "刘凝", //联系人
        contactNumber: "18889021871", //联系电话
        id: 8,
    },
    {
        postName: "服务员(兼职）", //岗位名称
        jobDescription: "岗位职责：1、熟悉公司小餐厅的工作情况，检查备用餐具是否齐全，餐台上永平是否整洁、齐备；2、工作时做到口勤、眼勤、手勤、脚勤，及时了解客人心态、需求；3、有牢固的业务操作知识，掌握客人的用餐规律；4、接到客人礼貌、周到、耐心，是可忍宾至如归。岗位要求：1、形象气质佳，有服务行业相关经验；2、有工作责任心，有独立处理用餐事务的能力；3、加强业务知识学习，不断掌握服务技能，提高服务质量。", //岗位描述
        salaryExpectation: "4-5K", //期望薪资
        yourEducation: "学历不限", //你的学历
        workingYears: "3-5年", //工作年限
        city: "上海静安", //所在城市
        contacts: "刘晶晶", //联系人
        contactNumber: "17890938768", //联系电话
        id: 9,
    },
    {
        postName: "广告优化经理", //岗位名称
        jobDescription: "1. 为客户以及合作伙伴提供从策划/媒介购买/效果监测/优化的全程营销服务；2. 对客户投放数据有深入了解并分析给出建议；3. 为客户提供技术工具的咨询解决方案；4. 向产品部门提供客户需求的反馈，协助产品部门的产品优化；5. 贯彻执行公司客户服务的计划和标准，全面提升客户服务质量；6. 与客户、同行业间建立良好的合作关系", //岗位描述
        salaryExpectation: "20-30K·16薪", //期望薪资
        yourEducation: "高中", //你的学历
        workingYears: "1-3年", //工作年限
        city: "上海徐汇", //所在城市
        contacts: "李栏", //联系人
        contactNumber: "19082766583", //联系电话
        id: 10,
    },
    {
        postName: "用户增长主管", //岗位名称
        jobDescription: "1.负责项目组用户增长策略研究，搭建完善的用户增长体系；2.从流量获取到用户注册、登录、交易、复购进行深度的数据分析，建立和完善用户画像；3.根据用户画像，策划不同的裂变活动，形态包括且不限于H5、公众号、小程序；4.建立增长数据监控模型，并进行数据分析，不断迭代增长效率；5.整合内外部资源，负责增长方案的落地，深度参与产品迭代和用户研究；", //岗位描述
        salaryExpectation: "15-30K", //期望薪资
        yourEducation: "学历不限", //你的学历
        workingYears: "学历不限", //工作年限
        city: "上海徐汇", //所在城市
        contacts: "李扫", //联系人
        contactNumber: "18988763887", //联系电话
        id: 11,
    },
    {
        postName: "医生合伙人", //岗位名称
        jobDescription: "职位描述：1、负责医院医生的洽谈与APP推广工作;2、负责长期维护和医生的业务关系;3、负责与医生传递企业理念及价值;4、协调匹配专家出诊;5、建立专家档案及与各部门协调配合各项业务工作等。6、此岗位为兼职 介意勿扰", //岗位描述
        salaryExpectation: "5-10K", //期望薪资
        yourEducation: "高中", //你的学历
        workingYears: "1-3年", //工作年限
        city: "上海徐汇", //所在城市
        contacts: "黄可", //联系人
        contactNumber: "19083776566", //联系电话
        id: 12,
    },
    {
        postName: "抖音推广", //岗位名称
        jobDescription: "1、负责公司抖音、快手等平台账号以及直播带货的付费推广，包括投放方案、预算、日常账户管理等，并对投放结果负责；2、能及时捕捉到市场的行情变动，及时做出对应调整方案；3、对素材有较高理解度，能随时判断什么样的素材可以投放出好的效果；4、监控投放数据，结合数据对广告进行有效调整，提升投放效果；", //岗位描述
        salaryExpectation: "21-40K", //期望薪资
        yourEducation: "大专", //你的学历
        workingYears: "3-5年", //工作年限
        city: "上海徐汇", //所在城市
        contacts: "黄框", //联系人
        contactNumber: "10928776678", //联系电话
        id: 13,
    },
    {
        postName: "推广专员", //岗位名称
        jobDescription: "1.负责天猫旗舰店的付费推广工作，千万/亿级别的预算采买淘内流量；2.制定店铺推广方案，紧密结合店铺运营策略执行推广计划，负责品销宝、直通车、超级推荐等投放规划、执行、优化、总结、汇报；3.重点针对钻展做出测试优化方案，包括测试定向人群、店铺等，测试素材与承接页，提升点击率和ROI，以及研究新项目，数据银行投放等；4.进行淘客招募，合作洽谈，与MCN机构对接，付费达人合作；5.负责淘宝站内搜索优化，提高自然搜索、千人千面流量；6.具有钻研精神，深挖研究某一课题得出结论并分享，致力于不断提升自己的能力；7.完成上级交办的其它任务。", //岗位描述
        salaryExpectation: "14-20K·15薪", //期望薪资
        yourEducation: "学历不限", //你的学历
        workingYears: "3-5年", //工作年限
        city: "上海闵行", //所在城市
        contacts: "周领", //联系人
        contactNumber: "18729098987", //联系电话
        id: 14,
    },
    {
        postName: "广告投放师", //岗位名称
        jobDescription: "1.结合产品特点负责在Google/Facebook等海外广告平台的广告投放管理工作，对投放效果负责；2.根据品牌定位、产品卖点、目标用户画像等制定相对应的广告投放策略；3跟踪投放效果，整理并分析投放数据，通过数据分析不断优化和调整广告投放策略；4.关注竞品动态及市场行情，及时调整广告投放方案并测试新类型广告投放形式。", //岗位描述
        salaryExpectation: "11-20K", //期望薪资
        yourEducation: "高中", //你的学历
        workingYears: "1-3年", //工作年限
        city: "上海闵行", //所在城市
        contacts: "周东", //联系人
        contactNumber: "17829098762", //联系电话
        id: 15,
    },
    {
        postName: "海外推广（红人、网盟）", //岗位名称
        jobDescription: "1、熟练掌握EDM、联盟、软文、博客、社交等营销手段，通过联盟合作，获取精准流量资源，帮助独立站获取目标客户、完成销售目标；2、根据产品规划在海外推广渠道开拓红人资源，制定广告推广方案，对广告进行成本预算、效果追踪，提交数据统计报告，独立完成相关的联盟广告投放工作，并不断挑选优质联盟资源进行推广；3、跟踪联盟的数据统计情况并完成联盟平台所发布的OFFER的广告投放情况；4、分析竞争对手的联盟推广，进行对比优化；5、开发Youtube视频、网络红人、论坛等营销内容渠道，推进红人和公司的内容合作，管理红人资源，配合规划测评内容、测评视频及软文发布，及时更新产品信息及后期关系维护；", //岗位描述
        salaryExpectation: "12-20K", //期望薪资
        yourEducation: "学历不限", //你的学历
        workingYears: "3-5年", //工作年限
        city: "上海闵行", //所在城市
        contacts: "王玲", //联系人
        contactNumber: "17920938877", //联系电话
        id: 16,
    },
    {
        postName: "产品经理（医药行业）", //岗位名称
        jobDescription: "工作职责：1.参与重点项目的系列产品规划，并提出响应的设计方案、竞品分析、需求整理；2.针对各需求部门、用户体验，收集并输出产品优化方案；3.根据需求输出高质量的产品文档、流程原型图；4.协同UE、UI人员输出高质量的交互、视觉稿；5.负责迭代过程中的资源协调，与运营、研发团队紧密配合，推动项目迭代有序高效运转，对产品质量负责。职位要求：1.本科以上学历，3-5年以上的互联网产品工作经验，有在线产品者优先；2.较强的战略思考能力和产品决策能力；3.具备较好的产品设计理念和严谨的逻辑思维；4.待人接物简单直接，有强烈的学习欲望，对市场和用户保持好奇心。", //岗位描述
        salaryExpectation: "15-22K", //期望薪资
        yourEducation: "大专", //你的学历
        workingYears: "1-3年", //工作年限
        city: "上海长宁", //所在城市
        contacts: "王经理", //联系人
        contactNumber: "16789298776", //联系电话
        id: 17,
    }
]
export default personnel